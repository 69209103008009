import { render, staticRenderFns } from "./ClientManage.vue?vue&type=template&id=7c65e292&scoped=true&"
import script from "./ClientManage.vue?vue&type=script&lang=js&"
export * from "./ClientManage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c65e292",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("d:\\workspace\\liveinfo3-front-admin-dev\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7c65e292')) {
      api.createRecord('7c65e292', component.options)
    } else {
      api.reload('7c65e292', component.options)
    }
    module.hot.accept("./ClientManage.vue?vue&type=template&id=7c65e292&scoped=true&", function () {
      api.rerender('7c65e292', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/apps/clients/client-manage/ClientManage.vue"
export default component.exports