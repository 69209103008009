<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="10"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-input
              v-model="clientName"
              placeholder="제약사명"
              class="el-def"
              @keyup.enter="refetchData()"
            />
            <b-form-input
              v-model="portalName"
              placeholder="포털명"
              class="el-def"
              @keyup.enter="refetchData()"
            />
            <b-form-datepicker
              id="datepicker-placeholder1"
              v-model="createDate.fr"
              class="el-def"
              placeholder="등록일(Fr)"
            />
            <label class="ml-25 mr-50">~</label>
            <b-form-datepicker
              id="datepicker-placeholder2"
              v-model="createDate.to"
              class="el-def"
              placeholder="등록일(To)"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="primary"
              class="btn-search"
              @click.prevent="refetchData()"
            >
              조회
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card
      no-body
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start"
          >
            <h5 class="mb-0 ml-50">
              제약사 등록 리스트 [{{ totalRecords }}] 건
            </h5>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refClientTable"
        primary-key="clientId"
        :items="fetchClients"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDesc"
        responsive
        sticky-header
        selectable
        select-mode="single"
        class="position-relative"
        @row-selected="onRowSelectedClient"
      >
        <!-- Column: clientName -->
        <template #cell(clientName)="data">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.value }}
          </span>
        </template>

        <!-- Column: clientPortalName -->
        <template #cell(clientPortalName)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>
      </b-table>
      <b-pagination
        v-model="currPage"
        :total-rows="totalRecords"
        limit="10"
        per-page="10"
        align="center"
      />
    </b-card>
    <b-card
      no-body
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start"
          >
            <feather-icon
              icon="EditIcon"
              size="19"
            />
            <h4 class="mb-0 ml-50">
              상세
            </h4>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="outline-primary"
              @click.prevent="resetClientData()"
            >
              <feather-icon
                icon="PlusSquareIcon"
                class="mr-50"
              />
              <span>신규 등록</span>
            </b-button>
            <b-button
              variant="primary"
              class="ml-1"
              @click.prevent="saveClient()"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span>저장</span>
            </b-button>
            <b-button
              variant="outline-secondary"
              class="ml-1"
              @click.prevent="removeClient()"
            >
              <feather-icon
                icon="Trash2Icon"
                class="mr-50"
              />
              <span>삭제</span>
            </b-button>
          </b-col>
        </b-row>
        <!-- Edit Form -->
        <validation-observer ref="formRules">
          <b-row class="mt-1">
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="제약사명"
                label-for="clientName"
              >
                <validation-provider
                  #default="{ errors }"
                  name="제약사명"
                  rules="required"
                >
                  <b-form-input
                    id="clientName"
                    v-model="clientData.clientName"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="제약사ID"
                label-for="clientLoginId"
              >
                <validation-provider
                  #default="{ errors }"
                  name="제약사ID"
                  rules="required"
                >
                  <b-form-input
                    id="clientLoginId"
                    v-model="clientData.clientLoginId"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="개인정보처리방침 내 제휴 제약사 표시 여부"
                label-for="isTermShow"
              >
                <b-form-checkbox
                  v-model="clientData.isTermShow"
                  switch
                  inline
                  class="custom-control-info"
                >
                  {{ clientData.isTermShow ? '표시' : '표시안함' }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </div>
    </b-card>
    <b-card>
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start"
        >
          <feather-icon
            icon="GridIcon"
            size="19"
          />
          <h4
            class="mb-0 ml-50"
            style="min-width: 120px"
          >
            웹 세미나 연동
          </h4>
          <b-form-checkbox
            v-model="clientData.isWebSeminarLink"
            switch
            inline
            class="custom-control-info"
          >
            {{ clientData.isWebSeminarLink ? '사용' : '사용안함' }}
          </b-form-checkbox>
        </b-col>
      </b-row>
      <validation-observer ref="linkRules">
        <b-row
          v-show="clientData.isWebSeminarLink"
          class="mt-1"
        >
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="포털명"
              label-for="clientPortalName"
            >
              <b-form-input
                id="clientPortalName"
                v-model="clientData.clientPortalName"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="웹 세미나 코드"
              label-for="webSeminarCode"
            >
              <div class="d-flex align-items-center">
                <b-form-input
                  id="webSeminarCode"
                  v-model="clientData.webSeminarCode"
                  readonly
                  class="mr-50"
                />
                <b-button
                  variant="primary"
                  class="btn-search"
                  :disabled="clientData.clientId === null || clientData.isCodeIssued"
                  @click.prevent="issueClientAccessCode()"
                >
                  {{ (clientData.isCodeIssued ? '발급 완료' : '코드 발급') }}
                </b-button>
              </div>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="2"
          >
            <b-form-group
              label="사전 예약 사용 여부"
              label-for="isPortalReserve"
            >
              <b-form-checkbox
                v-model="clientData.isPortalReserve"
                switch
                inline
                class="custom-control-info"
              >
                {{ clientData.isPortalReserve ? '사용' : '사용안함' }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="2"
          >
            <b-form-group
              label="사전 예약 취소 사용 여부"
              label-for="isPortalCancel"
            >
              <b-form-checkbox
                v-model="clientData.isPortalCancel"
                switch
                inline
                class="custom-control-info"
              >
                {{ clientData.isPortalCancel ? '사용' : '사용안함' }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="2"
          >
            <b-form-group
              label="사전 예약 결과값 전송 여부"
              label-for="isPortalReserveApi"
            >
              <b-form-checkbox
                v-model="clientData.isPortalReserveApi"
                switch
                inline
                class="custom-control-info"
              >
                {{ clientData.isPortalReserveApi ? '사용' : '사용안함' }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="프리패스 연동 사용 여부"
              label-for="isFreepass"
            >
              <b-form-checkbox
                v-model="clientData.isFreepass"
                switch
                inline
                class="custom-control-info"
                @change="isFreepassChanged"
              >
                {{ clientData.isFreepass ? '사용' : '사용안함' }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="포털 도메인 URL"
              label-for="portalDomain"
            >
              <b-form-input
                id="portalDomain"
                v-model="clientData.portalDomain"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="프리패스 인증 URL (*프리패스 연동 사용 시)"
              label-for="portalAuthorizeUrl"
            >
              <b-form-input
                id="portalAuthorizeUrl"
                v-model="clientData.portalAuthorizeUrl"
                :disabled="!clientData.isFreepass"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="2"
            class="mb-50"
          >
            <b-button
              variant="outline-primary"
              class="mt-0 mt-md-2"
              :disabled="!clientData.isFreepass"
              @click.prevent="showSignatureForm = true"
            >
              <feather-icon
                icon="PlusSquareIcon"
                class="mr-50"
              />
              <span>목록</span>
            </b-button>
          </b-col>
          <b-col cols="10" />
          <b-col cols="12">
            <b-form-group
              label="인증정보요청API 쿼리스트링 (*프리패스 연동 사용 시)"
              label-for="portalSignatureForm"
            >
              <b-form-input
                id="portalSignatureForm"
                v-model="clientData.portalSignatureForm"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="2"
            class="mb-50"
          >
            <b-button
              variant="outline-primary"
              class="mt-0 mt-md-2"
              :disabled="!clientData.isFreepass"
              @click.prevent="showSignUpForm = true"
            >
              <feather-icon
                icon="PlusSquareIcon"
                class="mr-50"
              />
              <span>목록</span>
            </b-button>
          </b-col>
          <b-col cols="10" />
          <b-col cols="12">
            <b-form-group
              label="신규가입정보요청API 쿼리스트링 (*프리패스 연동 사용 시)"
              label-for="portalSignUpInfoForm"
            >
              <b-form-textarea
                v-model="clientData.portalSignUpInfoForm"
                rows="6"
                readonly
              />
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>
    <set-portal-signature-form
      :show-signature-form="showSignatureForm"
      @close="signatureForm.close"
    />
    <set-portal-sign-up-form
      :show-sign-up-form="showSignUpForm"
      @close="signUpForm.close"
    />
  </div>
</template>

<script>
import axios from '@axios'
import dayjs from 'dayjs'
import { ref, watch, getCurrentInstance } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { errorFormatter } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SetPortalSignatureForm from './SetPortalSignatureForm.vue'
import SetPortalSignUpForm from './SetPortalSignUpForm.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    SetPortalSignatureForm,
    SetPortalSignUpForm,
  },

  setup() {
    const toast = useToast()
    const instance = getCurrentInstance()
    const bvModal = instance.proxy.$bvModal
    const refs = instance.proxy.$refs

    // Query Data
    const clientName = ref('')
    const portalName = ref('')
    const createDate = ref({ fr: null, to: null })
    // Query Data End.

    // Main Table Config
    const clientInitState = {
      clientId: 0,
      clientName: '',
      clientPortalName: '',
      clientLoginId: '',
      webSeminarCode: '',

      isTermShow: false,

      isCodeIssued: false,
      isWebSeminarLink: false,
      isPortalReserve: false,
      isPortalCancel: false,
      isPortalReserveApi: false,

      isFreepass: false,
      portalDomain: '',
      portalAuthorizeUrl: '',
      portalSignatureForm: '',
      portalSignUpInfoForm: '',
    }
    const clientData = ref({ ...clientInitState })

    const resetClientData = () => {
      Object.assign(clientData.value, clientInitState)
    }

    const isFreepassChanged = () => {
      if (clientData.value.isFreepass) {
        clientData.value.isTermShow = true
      }
    }

    const showSignatureForm = ref(false)
    const signatureForm = {
      close: rs => {
        showSignatureForm.value = false
        if (rs) {
          const formStr = rs.map(m => `${m.code}={${m.code.charAt(0).toUpperCase() + m.code.slice(1)}}`).join('&')
          clientData.value.portalSignatureForm = formStr
        }
      },
    }

    const showSignUpForm = ref(false)
    const signUpForm = {
      close: rs => {
        showSignUpForm.value = false
        if (rs) {
          const formStr = rs.map(m => `${m.code}={${m.code.charAt(0).toUpperCase() + m.code.slice(1)}}`).join('&')
          clientData.value.portalSignUpInfoForm = formStr
        }
      },
    }

    const refClientTable = ref(null)
    const sortBy = ref('createDate')
    const isSortDesc = ref(true)
    const currPage = ref(1)
    const totalRecords = ref(0)
    const dateFormatter = value => dayjs(new Date(value)).format('YY-MM-DD')
    const tableColumns = [
      { key: 'clientId', label: 'Id', sortable: true },
      { key: 'clientName', label: '제약사명', sortable: true },
      { key: 'clientPortalName', label: '포털명', sortable: true },
      { key: 'webSeminarCode', label: '웹 세미나 코드', sortable: true },
      {
        key: 'createDate', label: '등록일자', sortable: true, formatter: dateFormatter,
      },
      { key: 'createUserName', label: '등록자', sortable: true },
    ]
    const refetchData = () => {
      refClientTable.value.refresh()
    }
    watch([currPage], () => {
      refetchData()
    })
    const onRowSelectedClient = items => {
      const item = items[0]
      if (item) {
        getClient(item.clientId)
      }
      // Object.assign(clientData.value, item)
    }
    // Main Table Config End.

    const fetchClients = (ctx, callback) => {
      axios.post('/fa/client/list', {
        search: {
          clientName: clientName.value,
          portalName: portalName.value,
          createDateFr: createDate.value.fr ? `${createDate.value.fr}T00:00:00` : null,
          createDateTo: createDate.value.to ? `${createDate.value.to}T23:59:59` : null,
        },
        sort: {
          predicate: sortBy.value,
          reverse: isSortDesc.value,
        },
        pagination: {
          number: currPage.value,
        },
      })
        .then(rs => {
          const { items, totalRecord } = rs.data
          callback(items)
          totalRecords.value = totalRecord
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '데이터 조회에 실패하였습니다.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const getClient = id => {
      axios.get(`/fa/client/${id}`)
        .then(rs => {
          clientData.value = rs.data
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '데이터 조회에 실패하였습니다.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const saveClient = () => {
      const validPromise = refs.formRules.validate()
      validPromise.then(valid => {
        if (!valid) return

        const { clientId } = clientData.value
        if (clientId) {
          updateClient()
        } else {
          saveNewClient()
        }
      })
    }

    const saveNewClient = () => {
      axios.put('/fa/client/new', clientData.value)
        .then(rs => {
          toast({
            component: ToastificationContent,
            props: {
              title: '신규 제약사 등록 완료',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          if (rs.data.id) {
            clientData.value.clientId = rs.data.id
          }
          refetchData()
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: errorFormatter(error, '데이터를 저장하는 과정에서 오류가 발생하였습니다.'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const updateClient = () => {
      axios.patch(`/fa/client/update/${clientData.value.clientId}`, clientData.value)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '데이터 변경 완료',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          refetchData()
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: errorFormatter(error, '데이터를 변경하는 과정에서 오류가 발생하였습니다.'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const issueClientAccessCode = () => {
      if (!clientData.value.clientId) {
        toast({
          component: ToastificationContent,
          props: {
            title: '코드 발급 대상을 선택하세요. (신규 등록 제약사인 경우, 저장 후 다시 진행하세요.)',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
        return
      }
      axios.patch(`/fa/client/issue-code/${clientData.value.clientId}`)
        .then(rs => {
          if (rs.data.value) {
            clientData.value.isCodeIssued = true
            clientData.value.webSeminarCode = rs.data.value
            refetchData()
          }
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '코드 발급 과정에서 오류가 발생하였습니다.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const removeClient = () => {
      if (!clientData.value.clientId) {
        toast({
          component: ToastificationContent,
          props: {
            title: '삭제할 대상을 선택하세요.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
        return
      }
      bvModal
        .msgBoxConfirm('삭제 하시겠습니까?', {
          size: 'sm',
          cancelVariant: 'outline-secondary',
          centered: true,
        })
        .then(confirm => {
          if (!confirm) return

          axios.delete(`/fa/client/${clientData.value.clientId}`)
            .then(() => {
              toast({
                component: ToastificationContent,
                props: {
                  title: '데이터 삭제 완료',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              refetchData()
              resetClientData()
            })
            .catch(() => {
              toast({
                component: ToastificationContent,
                props: {
                  title: '삭제하는 과정에서 오류가 발생하였습니다.',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        })
    }

    return {
      clientName,
      portalName,
      createDate,
      tableColumns,
      refClientTable,
      sortBy,
      isSortDesc,
      refetchData,
      currPage,
      totalRecords,
      clientData,
      resetClientData,
      onRowSelectedClient,

      required,
      fetchClients,
      getClient,
      saveClient,
      removeClient,
      issueClientAccessCode,
      showSignatureForm,
      signatureForm,
      showSignUpForm,
      signUpForm,

      isFreepassChanged,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
