<!-- [팝업] 인증정보요청 API 전송 항목 선택 -->
<template>
  <b-modal
    v-model="showSignUpForm"
    cancel-variant="outline-secondary"
    centered
    ok-title="적용"
    cancel-title="취소"
    size="lg"
    title="신규가입정보요청 API 전송 항목"
    no-close-on-esc
    no-close-on-backdrop
    @ok.prevent="submit()"
    @close.prevent="close()"
    @cancel.prevent="close()"
  >
    <b-row class="mt-1">
      <b-col
        cols="12"
      >
        <b-form-checkbox-group
          v-model="selects"
          value-field="code"
          text-field="label"
        >
          <b-form-checkbox
            v-for="f in codes.forms"
            :key="f.code"
            :value="f.code"
            class="mb-25 mr-3"
            style="width: 120px"
          >
            {{ f.label }}
          </b-form-checkbox>
        </b-form-checkbox-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
  },

  props: {
    showSignUpForm: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, { emit }) {
    const toast = useToast()

    const selects = ref([])

    // Set Codes
    const codes = ref({
      forms: [
        { label: '이름', code: 'userName' },
        { label: '의사면허번호', code: 'licenseNum' },
        { label: '진료과', code: 'medicalDept' },
        { label: '근무처명', code: 'workPlace' },
        { label: '근무처주소', code: 'workAddress' },
        { label: '휴대폰번호', code: 'phoneNum' },
        { label: '본인인증CI값', code: 'identityCi' },
        { label: '이메일', code: 'email' },
        { label: '생년월일', code: 'birthDate' },
        { label: '성별', code: 'gender' },
        { label: '전문의여부', code: 'specType' },
        { label: '전공과', code: 'major' },
        { label: '전문의취득연도', code: 'specYear' },
        { label: '면허취득연도', code: 'licenseYear' },
      ],
    })

    // Set Codes End.
    const close = () => {
      emit('close', null)
    }

    const submit = () => {
      if (selects.value.length === 0) {
        toast({
          component: ToastificationContent,
          props: {
            title: '전송 항목을 한 개 이상 선택하세요.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
        return
      }

      const retVal = []
      const filts = codes.value.forms.filter(el => selects.value.indexOf(el.code) > -1)
      if (filts.length) {
        filts.forEach(el => {
          retVal.push(el)
        })
      }
      emit('close', retVal)
    }

    return {
      codes,
      close,
      submit,
      selects,
    }
  },
}
</script>
